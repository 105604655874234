.txtAroundWeb{
    font-size:1.3em
}

.aroundWebImg{
    width:10%
}

.id{
    font-size:1.3em;
    font-weight: bold;
}

.email{
    font-size:1.1em;
    font-style: italic;
}

.g-recaptcha {
    display: inline-block;
}
.contain{
    display: flex;
    align-items: center;
    justify-content: center;
}