.nav-item.nav-link{
    font-size: 1.2em;
}

.navbar-brand{
    font-size: 1.2em;
}

.contain{
    display: flex;
    align-items: center;
    justify-content: center;
}

button{
    white-space: normal!important;
}
/*
@media (min-width: 576px) {
    .card-columns {
        column-count: 2;
    }
}

@media (min-width: 768px) {
    .card-columns {
        column-count: 3;
    }
}

@media (min-width: 992px) {
    .card-columns {
        column-count: 4;
    }
}

@media (min-width: 1200px) {
    .card-columns {
        column-count: 5;
    }
}
*/