.icon-text{
    font-size: 15pt;
    white-space: nowrap;
}

.vertical-spacer{
    height: 20px;
}

.about-txt{
    font-size: 4vmin;
}