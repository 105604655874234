.center-vert {
  position: relative;
  top: 60%;
  transform: translateY(-50%);
  text-align: center;
}

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.profile{
  width: max(20vw, 20vh)!important;
  height: max(20vw, 20vh);
}